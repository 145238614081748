<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#3CB043" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="addressAddDialog" width="700" persistent>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card tile flat>
            <v-layout
              wrap
              justify-center
              px-8
              py-5
              style="background-color: #b6b6b626"
            >
              <v-flex xs11 text-left>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 18px;
                    color: #393939;
                  "
                >
                  New Address
                </span>
              </v-flex>
              <v-flex xs1>
                <v-icon @click="addressAddDialog = false" color="#000000"
                  >mdi-close</v-icon
                >
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-center
              pt-8
              px-5
              style="
                font-size: 14px;
                color: #1f1f1f;
                font-family: RobotoRegular;
              "
            >
              <v-flex xs12 sm12 text-left px-2>
                <span>Full Name </span>
                <v-text-field
                  ref="firstname"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="name"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 text-left px-2>
                <span>Address </span>
                <v-text-field
                  ref="streetAddress"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="address"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> City </span>
                <v-text-field
                  ref="city"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="city"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> District </span>
                <v-text-field
                  ref="district"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="district"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> State </span>
                <v-select
                  ref="state"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :items="states"
                  item-text="name"
                  item-value="value"
                  :rules="[rules.required]"
                  v-model="state"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> Country </span>
                <v-text-field
                  ref="country"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="country"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> Pincode </span>
                <v-text-field
                  ref="pincode"
                  background-color="#F5F5F5"
                  solo
                  flat
                  type="number"
                  dense
                  :rules="[rules.required]"
                  v-model="pincode"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> Phone </span>
                <v-text-field
                  ref="phone"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  type="number"
                  :rules="[rules.required]"
                  v-model="phone"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-end py-5>
              <v-flex xs6 px-2 text-right>
                <v-btn
                  :ripple="false"
                  depressed
                  block
                  tile
                  color="#30B868"
                  @click="validateInput"
                >
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 12px;
                      color: #fff;
                      text-transform: none;
                    "
                  >
                    Add Address
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
    <v-dialog v-model="editDialog" width="900" persistent>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card tile flat>
            <v-layout
              wrap
              justify-center
              px-8
              py-5
              style="background-color: #b6b6b626"
            >
              <v-flex xs11 text-left>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 18px;
                    color: #393939;
                  "
                >
                  Edit Address
                </span>
              </v-flex>
              <v-flex xs1>
                <v-icon @click="editDialog = false" color="#000000"
                  >mdi-close</v-icon
                >
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-center
              pt-8
              px-5
              style="
                font-size: 14px;
                color: #1f1f1f;
                font-family: RobotoRegular;
              "
            >
              <v-flex xs12 sm12 text-left px-2>
                <span>Full Name </span>
                <v-text-field
                  ref="firstname"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="editItem.name"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 text-left px-2>
                <span>Address </span>
                <v-text-field
                  ref="address"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="editItem.address"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> City </span>
                <v-text-field
                  ref="city"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="editItem.city"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> District </span>
                <v-text-field
                  ref="district"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="editItem.district"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> State </span>
                <v-select
                  ref="state"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  item-text="name"
                  item-value="value"
                  :items="states"
                  :rules="[rules.required]"
                  v-model="editItem.state"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> Country </span>
                <v-text-field
                  ref="country"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="editItem.country"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> Pincode </span>
                <v-text-field
                  ref="pincode"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  type="number"
                  :rules="[rules.required]"
                  v-model="editItem.pincode"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> Phone </span>
                <v-text-field
                  ref="phone"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  type="number"
                  :rules="[rules.required]"
                  v-model="editItem.phone"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-end py-5>
              <v-flex xs6 px-2 text-right>
                <v-btn
                  :ripple="false"
                  depressed
                  block
                  tile
                  color="#30B868"
                  @click="editAddress"
                >
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 12px;
                      color: #fff;
                      text-transform: none;
                    "
                  >
                    Edit Address
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs11 pt-5>
        <v-layout wrap>
          <v-flex xs12 sm6 md8 text-left>
            <span
              style="
                color: #1f1f1f;
                font-family: poppinssemibold;
                font-size: 18px;
              "
              >Saved Address</span
            >
          </v-flex>
          <v-flex xs12 sm6 md4 text-right>
            <v-btn outlined color="grey" @click="addressAddDialog = true"
              ><span style="color: #30b868; font-family: poppinsregular"
                >+ Add new address</span
              ></v-btn
            >
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center v-if="list.length > 0" pt-5 pb-2>
          <v-flex xs12 py-2 v-for="(item, i) in list" :key="i">
            <v-card>
              <v-layout wrap justify-center py-5 px-8>
                <v-flex xs2 sm1 md1 lg1>
                  <v-radio-group
                    :hide-details="true"
                    v-model="item.isDefault"
                    column
                  >
                    <v-radio
                      @click="changeDefault(item)"
                      color="#30B868"
                      :value="true"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex xs10 sm11 text-left>
                  <v-layout wrap justify-start fill-height>
                    <v-flex xs12 sm6 md5 lg4 text-left>
                      <v-layout
                        wrap
                        justify-start
                        style="line-height: 16px"
                        fill-height
                      >
                        <v-flex xs12>
                          <span
                            style="
                              font-family: poppinssemibold;
                              font-size: 15px;
                              color: #000000;
                            "
                          >
                            {{ item.name }}
                          </span>
                        </v-flex>
                        <v-flex xs12 pt-3>
                          <span
                            style="
                              color: #191919;
                              font-size: 13px;
                              font-family: poppinsmedium;
                            "
                            >{{ item.address }}, {{ item.city }},
                            {{ item.district }}, {{ item.state }},
                            {{ item.country }},
                            {{ item.pincode }}
                          </span>
                        </v-flex>
                        <v-flex xs12 pt-2>
                          <span
                            style="
                              color: #191919;
                              font-size: 13px;
                              font-family: poppinsmedium;
                            "
                            >Mobile : {{ item.phone }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm6 md5 lg8>
                      <v-layout wrap justify-end pt-3>
                        <v-flex xs6 sm6 md3 align-self-end text-right>
                          <v-btn
                            outlined
                            small
                            color="#49A1F2"
                            @click="(editDialog = true), (editItem = item)"
                            ><span
                              style="
                                color: #49a1f2;
                                font-family: poppinsregular;
                              "
                            >
                              <v-icon small color="#49A1F2">mdi-pencil</v-icon>
                              edit</span
                            ></v-btn
                          >
                        </v-flex>
                        <v-flex xs6 sm6 md3 align-self-end text-right>
                          <v-dialog
                            v-model="item.dialogDelete"
                            max-width="600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                outlined
                                small
                                color="#EC6D6D"
                                v-on="on"
                                v-bind="attrs"
                                ><span
                                  style="
                                    color: #ec6d6d;
                                    font-family: poppinsregular;
                                  "
                                >
                                  <v-icon small color="#EC6D6D"
                                    >mdi-trash-can-outline</v-icon
                                  >
                                  Remove</span
                                ></v-btn
                              >
                            </template>
                            <v-card>
                              <v-card-title
                                style="
                                  font-family: poppinsregular;
                                  font-size: 15px;
                                  color: #000;
                                "
                                >Are you sure you want to delete this
                                Address?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  small
                                  @click="item.dialogDelete = false"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  small
                                  @click="removeAddress(item)"
                                  >OK</v-btn
                                >
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-5 v-else>
          <v-flex xs12 pa-2 align-self-center>
            <span
              style="
                font-family: poppinsregular;
                font-size: 20px;
                color: #000000;
                cursor: pointer;
              "
              >Oops! No Address Available</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      ServerError: false,
      timeout: 5000,
      msg: null,
      addressAddDialog: false,
      editDialog: false,
      selected: false,
      editItem: "",
      name: null,
      address: null,
      city: null,
      district: null,
      state: null,
      country: null,
      pincode: null,
      phone: null,
      list: [],
      states: [],
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => value.length >= 8 || "Min 8 characters",
      },
    };
  },
  computed: {
    form() {
      return {
        name: this.name,
        address: this.address,
        city: this.city,
        district: this.district,
        state: this.state,
        country: this.country,
        pincode: this.pincode,
        phone: this.phone,
      };
    },
    //   userData()
    //   {
    //     return this.$store.state.userData
    //   }
  },
  beforeMount() {
    this.getAddress();
    this.getState();
  },
  methods: {
    validateInput() {
      this.formHasErrors = false;
      var phoneformat = /^\d{10}$/;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) {
          this.formHasErrors = true;
          this.msg = "Please Provide " + f;
          this.showSnackBar = true;
          return;
        }
        // if (!this.$refs[f].validate(true)) {
        //   this.msg = "Please Provide Valid " + f;
        //   this.showSnackBar = true;
        //   this.formHasErrors = true;
        //   return;
        // }
      });
      if (!this.phone.match(phoneformat)) {
        this.msg = "Please Provide valid Phone Number";
        this.showSnackBar = true;
        return;
      }
      if (this.formHasErrors == false) this.addAddress();
    },
    getAddress() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/deliveryAddress/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            if (response.data.data) {
              this.list = response.data.data;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getState() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/states/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            if (response.data.data) {
              this.states = response.data.data;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    addAddress() {
      this.appLoading = true;
      var address = {};
      address["name"] = this.name;
      address["address"] = this.address;
      address["city"] = this.city;
      address["district"] = this.district;
      address["state"] = this.state;
      address["country"] = this.country;
      address["pincode"] = this.pincode;
      address["phone"] = this.phone;
      axios({
        method: "POST",
        url: "/deliveryAddress/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: address,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.name = null;
            this.address = null;
            this.city = null;
            this.district = null;
            this.state = null;
            this.country = null;
            this.pincode = null;
            this.phone = null;
            this.addressAddDialog = false;
            this.getAddress();
          } else {
            this.msg = "Something went wrong";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    editAddress() {
      this.appLoading = true;
      var address = {};
      address["id"] = this.editItem._id;
      address["name"] = this.editItem.name;
      address["address"] = this.editItem.address;
      address["city"] = this.editItem.city;
      address["district"] = this.editItem.district;
      address["state"] = this.editItem.state;
      address["country"] = this.editItem.country;
      address["pincode"] = this.editItem.pincode;
      address["phone"] = this.editItem.phone;
      axios({
        method: "POST",
        url: "/deliveryAddress/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: address,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editDialog = false;
            this.getAddress();
          } else {
            this.msg = "Something went wrong";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    changeDefault(item) {
      this.appLoading = true;
      var address = {};
      address["id"] = item._id;
      axios({
        method: "POST",
        url: "/deliveryAddress/change",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: address,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.getAddress();
          } else {
            this.msg = "Something went wrong";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    removeAddress(item) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/deliveryAddress/remove",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: item._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialogDelete = false;
            this.getAddress();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>